import { Controller } from '@hotwired/stimulus';
import { SplitTestFeature } from '../../splitTesting';

export default class extends Controller {
  static values = {
    name: String,
  };

  initialize() {
    const variant = 'Hidden';
    const scriptSplitTest = new SplitTestFeature(this.nameValue, [variant]);
    scriptSplitTest.start();

    window[`split-test-${this.nameValue}`] = scriptSplitTest.variant;
  }
}
