import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

import { formToQueryString } from '../../utils';

export default class extends Controller {
  static targets = ['form'];

  search(event) {
    Rails.fire(this.formTarget, 'submit');
    this.updateQuery(event);
  }

  handleResults(event) {
    const [data] = event.detail;
    const selector = '[data-render-target="results"]';
    $(selector).replaceWith($(selector, data));
  }

  updateQuery(event) {
    const filterPath = this.formTarget.getAttribute('data-filter-path');
    const additionalParams = {};
    additionalParams[event.target.name] = event.target.value;

    const url = `${filterPath}?${formToQueryString(this.element, additionalParams)}`;
    window.history.pushState({ turbolinks: {} }, '', url);
  }
}
