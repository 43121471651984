import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['general', 'performance', 'economy', 'practicality'];

  switch(event) {
    this.element.querySelector('.tab-pane.active').classList.remove('active');
    this[`${event.target.value}Target`].classList.add('active');
  }
}
