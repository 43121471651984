import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['content'];

  connect() {
    const cap_id = this.contentTarget.dataset.id;
    const url = `/car-and-driving/${cap_id}`;

    if (cap_id && url) {
      Rails.ajax({
        url,
        type: 'GET',
        dataType: 'json',
        accept: 'application/json',
        success: (result) => {
          this.contentTarget.outerHTML = result.html;
        },
        error: (_xhr, _status, error) => {
          console.log(error);
        },
      });
    }
  }
}
