import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    path: String,
    id: String,
  };

  push() {
    if (this.pathValue && this.idValue) {
      window.localStorage.setItem('listPath', this.pathValue);
      window.localStorage.setItem('listID', this.idValue);
    }
  }
}
