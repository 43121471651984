import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    channel: String,
  };

  static targets = ['makes', 'modelRanges', 'models', 'derivativeGroups', 'derivatives', 'submit'];

  fetchModelRanges(event) {
    const url = `/${this.channelValue}/quotations/model_ranges?make_id=${event.target.value}`;

    this.disableSubmit();
    this.resetVehicles([
      this.modelRangesTarget,
      this.modelsTarget,
      this.derivativeGroupsTarget,
      this.derivativesTarget,
    ]);
    this.fetchData(url, this.modelRangesTarget);
  }

  fetchModels(event) {
    const url = `/${this.channelValue}/quotations/models?model_range_id=${event.target.value}`;

    this.disableSubmit();
    this.resetVehicles([this.modelsTarget, this.derivativeGroupsTarget, this.derivativesTarget]);
    this.fetchData(url, this.modelsTarget);
  }

  fetchDerivativeGroups(event) {
    const url = `/${this.channelValue}/quotations/derivative_groups?model_id=${event.target.value}`;

    this.disableSubmit();
    this.resetVehicles([this.derivativeGroupsTarget, this.derivativesTarget]);
    this.fetchData(url, this.derivativeGroupsTarget);
  }

  fetchDerivatives(event) {
    const url = `/${this.channelValue}/quotations/derivatives?capcode=${event.target.value}`;

    this.disableSubmit();
    this.resetVehicles([this.derivativesTarget]);
    this.fetchData(url, this.derivativesTarget);
  }

  selectDerivative(event) {
    if (event.target.value) {
      this.enableSubmit();
    } else {
      this.disableSubmit();
    }
  }

  fetchData(url, target) {
    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.populateVehicleList(response, target);
        this.showTarget(target);
      });
  }

  populateVehicleList(vehicles, target) {
    vehicles.forEach((vehicle) => {
      const option = document.createElement('option');
      option.value = vehicle.id;
      option.text = vehicle.name;
      target.appendChild(option);
    });
  }

  resetVehicles(targets) {
    targets.forEach((target) => {
      const options = target.querySelectorAll('option:not(:first-child)');

      this.hideTarget(target);
      options.forEach((option) => {
        option.remove();
      });
    });
  }

  hideTarget(target) {
    target.closest('.select_vehicle').classList.add('invisible');
  }

  showTarget(target) {
    target.closest('.select_vehicle').classList.remove('invisible');
  }

  disableSubmit() {
    this.submitTarget.disabled = true;
  }

  enableSubmit() {
    this.submitTarget.disabled = false;
  }
}
