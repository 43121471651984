import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  getGoogleAnaylticsAttribute(element, name) {
    const attributeName = `data-ga-model-${name}`;
    const elementWithData = element.closest(`[${attributeName}]`);
    return elementWithData ? elementWithData.getAttribute(attributeName) : null;
  }

  getSelectedOption(element) {
    return element.tagName === 'SELECT' ? element.options[element.selectedIndex].text : null;
  }

  push(event) {
    const featuredDealsInteraction = this.getGoogleAnaylticsAttribute(event.target, 'featured-deals-interaction');
    const filterAppliedCategory = this.getGoogleAnaylticsAttribute(event.target, 'filter-applied-category');
    const filterAppliedOption = this.getSelectedOption(event.target);
    const modelClicked = this.getGoogleAnaylticsAttribute(event.target, 'model-clicked');
    const isSwiped = event.type === 'slide-stop';

    const data = {
      event: 'model_page',
      featured_deals_interaction: featuredDealsInteraction || 'N/A',
      model_table_filter_applied_category: filterAppliedCategory || 'N/A',
      model_table_filter_applied_option: filterAppliedOption || 'N/A',
      model_table_model_clicked: modelClicked || 'N/A',
      swipe_featured_cards: isSwiped ? 'swiped' : 'N/A',
    };

    dataLayer.push(data);
  }
}
