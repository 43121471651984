import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    if (!this.carCardGridTarget()) return;

    this.observeElementInViewport(this.element);
  }

  observeElementInViewport(element) {
    const options = { threshold: 0.5 };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.pushDataLayer(element);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    observer.observe(element);
  }

  pushDataLayer(element) {
    const pathValue = element.getAttribute('data-ga-car-card-path');
    const itemBrandValue = element.getAttribute('data-ga-car-card-item-brand');
    const itemNameValue = element.getAttribute('data-ga-car-card-item-name');
    const itemIdValue = element.getAttribute('data-ga-car-card-item-id');
    const priceValueRaw = element.getAttribute('data-ga-car-card-price');
    const priceValue = priceValueRaw ? Number(priceValueRaw.replace(/£|,|\./g, '')) : null;
    const itemCategoryValue = element.getAttribute('data-ga-car-card-item-category');
    const itemVariantValue = element.getAttribute('data-ga-car-card-item-variant');

    const index = [...this.carCardGridTarget().children].indexOf(element);

    const data = {
      event: 'view_item_list',
      ecommerce: {
        item_list_id: this.listIdValue(pathValue),
        item_list_name: this.listNameValue(pathValue),
        items: [
          {
            item_list_id: this.listIdValue(pathValue),
            item_list_name: this.listNameValue(pathValue),
            item_brand: itemBrandValue,
            item_name: itemNameValue,
            item_variant: itemVariantValue,
            item_id: itemIdValue,
            price: priceValue,
            affiliation: 'Select Car Leasing',
            item_category: itemCategoryValue,
            index: index + 1,
            quantity: 1,
          },
        ],
      },
    };

    dataLayer.push({ ecommerce: null });
    dataLayer.push(data);
  }

  carCardGridTarget() {
    return this.element.closest('[data-ga-car-card-target="car-card-grid"]');
  }

  listIdValue(value) {
    return value.slice(1).replace(/\//g, '-');
  }

  listNameValue(value) {
    return value.slice(1).replace(/\//g, ' ').replace(/-/g, ' ');
  }
}
