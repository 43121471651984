import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  click(event) {
    if (!event.target.hash.match(/^#.+/)) return;

    const target = document.querySelector(event.target.hash);

    if (!target) return;

    event.preventDefault();

    // the use of 100 here is an attempt to ensure
    // that the huge sticky header doesn't cover the content
    const top = Math.floor(target.getBoundingClientRect().top) - 100;

    window.scrollBy({ top, left: 0, behavior: 'smooth' });
  }
}
