import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

const trackingKeyAttribute = 'data-key';

export default class extends Controller {
  static targets = ['nextContainer', 'previousContainer', 'nextButton', 'previousButton'];

  static values = {
    id: String,
  };

  connect() {
    this.loading = false;

    if (window.localStorage) {
      /**
       * delay the restore of result position
       * to ensure it doesn't clash with the browser trying to do the same
       */
      window.setTimeout(() => this.restoreLastClickedResultInViewport(), 500);
      this.trackLastClickedResult();
    }
  }

  /**
   * When someone clicks back from a result to the list,
   * we want to reposition the viewport on the result they clicked so they can carry on browsing.
   */
  restoreLastClickedResultInViewport() {
    const lastClickedResult = window.localStorage.getItem('LastClickedResult');
    const LastClickedResultPosition = window.localStorage.getItem('LastClickedResultPosition');

    if (lastClickedResult) {
      this.restoreElementPositionInViewport(
        this.element.querySelector(`[${trackingKeyAttribute}="${lastClickedResult}"]`),
        LastClickedResultPosition,
      );

      window.localStorage.removeItem('LastClickedResult');
      window.localStorage.removeItem('LastClickedResultPosition');
    }
  }

  /**
   * To support restoreLastClickedResultInViewport,
   * we stored the unique ID of the last clicked result.
   */
  trackLastClickedResult() {
    this.element.addEventListener('click', (event) => {
      const trackableResult = event.target.closest(`[${trackingKeyAttribute}]`);

      if (trackableResult) {
        window.localStorage.setItem('LastClickedResult', trackableResult.getAttribute(trackingKeyAttribute));
        // Gets last clicked top position relative to the top of window.
        window.localStorage.setItem('LastClickedResultPosition', trackableResult.getBoundingClientRect().top);
      }
    });
  }

  /**
   * Restores a DOM elements position relative to the top of window.
   */
  restoreElementPositionInViewport(element, position) {
    if (!element) return;

    element.scrollIntoView();
    window.scrollBy(0, -position);
  }

  /**
   * We want to keep the buttons in the DOM in case we need them later.
   */
  hideElement(element) {
    if (element) {
      element.style.display = 'none';
    }
  }

  /**
   * Handles the Ajax loading of an anchor.
   */
  _load(direction) {
    if (this.loading) return;

    const button = this[`${direction}ButtonTarget`];
    const insertPosition = direction === 'next' ? 'afterend' : 'beforebegin';
    const resultsLocation = direction === 'next' ? 'nextSibling' : 'previousSibling';
    const oppositeDirection = direction === 'next' ? 'previous' : 'next';

    this.loading = true;

    Rails.ajax({
      url: button.href,
      type: 'GET',
      success: (result) => {
        const newRecordsById = result.querySelector(`[data-load-more-pagination-id-value="${this.idValue}"]`);

        const newRecords = newRecordsById || result.querySelector('[data-controller="load-more-pagination"]');

        // add the new results before/after the current ones
        this.element.insertAdjacentHTML(insertPosition, newRecords.outerHTML);

        // hide the other direction's button
        // e.g. if you've clicked "View next results" button,
        // hide the "View previous results" from the new results
        this.hideElement(
          this.element[resultsLocation].querySelector(
            `[data-load-more-pagination-target="${oppositeDirection}Container"]`,
          ),
        );

        // hide the clicked button
        this.hideElement(this.element.querySelector(`[data-load-more-pagination-target="${direction}Container"]`));

        // add to browser history
        // using replace state here, as really you're "on the same page",
        // and we provide an alternative way of viewing the previous page's results
        window.history.replaceState({ turbolinks: {} }, '', button.href);

        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  next(event) {
    event.preventDefault();

    this._load('next');
  }

  previous(event) {
    event.preventDefault();

    this._load('previous');
  }
}
