import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  connect() {
    Rails.ajax({
      url: `/${this.element.getAttribute('data-channel-slug')}/related-deals/${this.element.getAttribute(
        'data-derivative-id',
      )}.js`,
      type: 'GET',
      success: (document) => {
        this.element.outerHTML = document.documentElement.innerHTML;

        const $relatedDeals = $('#related-deals');

        if ($relatedDeals.length === 0) {
          // no deals to show, so show the other deals, and remove the tabs
          const $tabs = $relatedDeals.closest('.c-tabs');
          $tabs.find('.c-tabs__nav-title:last')[0].click();
          $tabs.find('.c-tabs__nav').remove();
        }
      },
    });
  }
}
