import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];

  connect() {
    this.inputTargets.forEach((input) => {
      input.style.resize = 'none';
      input.style.lineHeight = '1.5em';
      input.style.height = 'calc(1.5em + 0.75rem + 2px)';
      input.style.minHeight = `${this.inputTarget.scrollHeight}px`;
      input.style.overflow = 'hidden';
    });
  }

  resize(event) {
    event.target.style.height = '5px';
    event.target.style.height = `${event.target.scrollHeight}px`;
  }
}
