import { Controller } from '@hotwired/stimulus';

export default class ScrollSliderController extends Controller {
  static targets = ['items', 'previousButton', 'nextButton', 'dots'];

  static values = {
    autoScroll: Boolean,
    interval: { type: Number, default: 3000 },
    showDots: Boolean,
  };

  connect() {
    this.initializeSlider();
    this.addEventListeners();
    if (this.autoScrollValue) this.startAutoScroll();
  }

  disconnect() {
    this.removeEventListeners();
    this.stopAutoScroll();
  }

  initializeSlider() {
    this.itemWidth = this.itemsTarget.scrollWidth / this.itemsTarget.children.length;
    if (this.showDotsValue) this.initializeDots();
    this.checkButtonVisibility();
  }

  addEventListeners() {
    this.boundHandleResize = this.handleResize.bind(this);
    this.boundHandleScroll = this.handleScroll.bind(this);
    window.addEventListener('resize', this.boundHandleResize, { passive: true });
    this.itemsTarget.addEventListener('scroll', this.boundHandleScroll, { passive: true });
    this.itemsTarget.addEventListener('mousedown', this.handleMouseDown.bind(this), { passive: false });
    this.itemsTarget.addEventListener('wheel', this.stopAutoScroll.bind(this), { passive: true });
    this.itemsTarget.addEventListener('touchstart', this.handleTouchStart.bind(this), { passive: true });
    this.itemsTarget.addEventListener('touchmove', this.handleTouchMove.bind(this), { passive: false });
  }

  removeEventListeners() {
    window.removeEventListener('resize', this.boundHandleResize);
    this.itemsTarget.removeEventListener('scroll', this.boundHandleScroll);
    // Remove other event listeners...
  }

  handleResize() {
    this.checkButtonVisibility();
    this.resetScrollPosition();
    if (this.showDotsValue) this.initializeDots();
  }

  handleScroll() {
    this.updateActiveDot();
  }

  handleMouseDown(event) {
    this.isDragging = true;
    this.stopAutoScroll();
    this.startX = event.pageX - this.itemsTarget.offsetLeft;
    this.scrollLeft = this.itemsTarget.scrollLeft;
    document.addEventListener('mousemove', this.handleMouseMove.bind(this), { passive: false });
    document.addEventListener('mouseup', this.handleMouseUp.bind(this), { passive: true });
  }

  handleMouseMove(event) {
    if (!this.isDragging) return;
    event.preventDefault();
    const x = event.pageX - this.itemsTarget.offsetLeft;
    const walk = (x - this.startX) * 2;
    this.itemsTarget.scrollLeft = this.scrollLeft - walk;
  }

  handleMouseUp() {
    this.isDragging = false;
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
  }

  handleTouchStart(event) {
    this.touchStartX = event.touches[0].clientX;
    this.touchStartY = event.touches[0].clientY;
  }

  handleTouchMove(event) {
    const moveX = event.touches[0].clientX - this.touchStartX;
    const moveY = event.touches[0].clientY - this.touchStartY;
    if (Math.abs(moveX) > Math.abs(moveY)) {
      event.preventDefault();
      this.itemsTarget.scrollLeft -= moveX;
    }
  }

  next() {
    this.scroll(this.getItemWidth());
  }

  previous() {
    this.scroll(-this.getItemWidth());
  }

  scroll(amount) {
    const newScrollLeft = this.itemsTarget.scrollLeft + amount;
    const maxScrollLeft = this.itemsTarget.scrollWidth - this.itemsTarget.offsetWidth;

    if (newScrollLeft < 0) {
      this.itemsTarget.scrollLeft = maxScrollLeft;
    } else if (newScrollLeft > maxScrollLeft) {
      this.itemsTarget.scrollLeft = 0;
    } else {
      this.itemsTarget.scrollLeft = newScrollLeft;
    }

    this.checkButtonVisibility();
    this.updateActiveDot();
  }

  getItemWidth() {
    return Math.floor(this.itemsTarget.children[0].getBoundingClientRect().width);
  }

  checkButtonVisibility() {
    if (!this.hasPreviousButtonTarget || !this.hasNextButtonTarget) return;

    const isOverflowing = this.itemsTarget.scrollWidth > this.itemsTarget.offsetWidth;
    this.previousButtonTarget.style.display = isOverflowing ? 'block' : 'none';
    this.nextButtonTarget.style.display = isOverflowing ? 'block' : 'none';
    this.itemsTarget.classList.toggle('slider-enabled', isOverflowing);

    if (this.showDotsValue) {
      const shouldShowDots = isOverflowing && this.dotsTarget.children.length > 1;
      this.dotsTarget.style.display = shouldShowDots ? 'flex' : 'none';
    }
  }

  startAutoScroll() {
    this.stopAutoScroll();
    this.autoScrollInterval = setInterval(() => this.next(), this.intervalValue);
  }

  stopAutoScroll() {
    clearInterval(this.autoScrollInterval);
  }

  autoScrollValueChanged() {
    this.autoScrollValue ? this.startAutoScroll() : this.stopAutoScroll();
  }

  initializeDots() {
    if (!this.hasDotsTarget) return;

    const itemCount = this.itemsTarget.children.length;
    const visibleItems = Math.floor(this.itemsTarget.offsetWidth / this.itemWidth);
    const dotCount = Math.max(
      2,
      Math.min(
        Math.ceil((this.itemsTarget.scrollWidth - this.itemsTarget.offsetWidth) / this.itemWidth) + 1,
        itemCount - visibleItems + 1,
      ),
    );

    this.dotsTarget.innerHTML = Array(dotCount).fill('<span class="slider-dot"></span>').join('');
    this.updateActiveDot();
    this.checkButtonVisibility();
  }

  updateActiveDot() {
    if (!this.showDotsValue || !this.hasDotsTarget) return;

    const progress = this.itemsTarget.scrollLeft / (this.itemsTarget.scrollWidth - this.itemsTarget.offsetWidth);
    const activeIndex = Math.min(
      Math.round(progress * (this.dotsTarget.children.length - 1)),
      this.dotsTarget.children.length - 1,
    );

    Array.from(this.dotsTarget.children).forEach((dot, index) => {
      dot.classList.toggle('active', index === activeIndex);
    });
  }

  resetScrollPosition() {
    this.itemsTarget.scrollLeft = 0;
  }
}
