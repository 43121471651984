import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'trigger'];

  toggle(event) {
    event.preventDefault();

    const name = event.currentTarget.getAttribute('data-toggle');
    const content = document.querySelector(`[data-toggle-target="${name}"]`);
    const triggers = document.querySelectorAll(`[data-toggle="${name}"]`);

    content.classList.toggle('show');

    triggers.forEach((trigger) => {
      trigger.classList.toggle('show');
    });
  }
}
