import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['slide'];

  initialize() {
    this.element.dataset.index = 0;
    this.showCurrentSlide();
    this.updateButtonStates();

    this.mutationObserver = new MutationObserver(() => {
      this.showCurrentSlide();
      this.updateButtonStates();
    });
  }

  connect() {
    this.mutationObserver.observe(this.element, {
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ['data-index'],
    });
  }

  disconnect() {
    this.mutationObserver.disconnect();
  }

  next() {
    if (this.element.dataset.index === this.slideTargets.length - 1) {
      return;
    }

    this.element.dataset.index++;
  }

  previous() {
    if (this.element.dataset.index === 0) {
      return;
    }

    this.element.dataset.index--;
  }

  showCurrentSlide() {
    this.slideTargets.forEach((element, index) => {
      element.hidden = index !== Number(this.element.dataset.index);
    });
  }

  updateButtonStates() {
    const previousButton = this.element.querySelector('[data-action="slideshow#previous"]');
    const nextButton = this.element.querySelector('[data-action="slideshow#next"]');

    if (Number(this.element.dataset.index) === 0) {
      previousButton.disabled = true;
    } else {
      previousButton.disabled = false;
    }

    if (Number(this.element.dataset.index) === this.slideTargets.length - 1) {
      nextButton.disabled = true;
    } else {
      nextButton.disabled = false;
    }
  }
}
