import { Controller } from '@hotwired/stimulus';
import { SplitTestFeature } from '../../splitTesting';

export default class extends Controller {
  static values = {
    name: String,
  };

  initialize() {
    const variant = 'Hidden';
    const styleUpdateSplitTest = new SplitTestFeature(this.nameValue, [variant]);
    styleUpdateSplitTest.start();

    if (styleUpdateSplitTest.variant === variant) {
      this.element.style = 'display:none;';
    }
  }
}
