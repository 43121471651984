import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button'];

  accept(event) {
    const target = event.target;

    if (target.checked) {
      this.buttonTarget.disabled = false;
      this.buttonTarget.classList.remove('disabled');
    } else {
      this.buttonTarget.disabled = true;
      this.buttonTarget.classList.add('disabled');
    }
  }
}
