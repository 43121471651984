import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  change(event) {
    const selectbox = event.target;
    const selectedOption = selectbox.options[selectbox.selectedIndex];

    window.location.href = selectedOption.getAttribute('value');
  }
}
