import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'button'];

  connect() {
    this.updateCount();
  }

  updateCount() {
    const modelCount = this.checkboxTargets.reduce((sum, checkbox) => {
      return checkbox.checked ? sum + parseInt(checkbox.dataset.makeMobileFilterCount, 10) : sum;
    }, 0);

    const total = modelCount === 0 ? 'all' : modelCount;

    this.buttonTarget.textContent = `View ${total} models`;
  }

  checkboxSelect(event) {
    const checkbox = event.currentTarget.querySelector('input[type="checkbox"]');
    if (event.target.tagName !== 'INPUT') {
      event.preventDefault();
      checkbox.checked = !checkbox.checked;
    }
    this.updateCount();
  }

  radioSelect(event) {
    const radio = event.currentTarget.querySelector('input[type="radio"]');
    const form = this.element.closest('form');

    if (radio && !radio.checked) {
      radio.checked = true;
      form.dispatchEvent(new Event('submit', { bubbles: true }));
    } else if (event.target === radio) {
      form.dispatchEvent(new Event('submit', { bubbles: true }));
    }

    this.closeDrawer();
  }

  clearAll() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false;
    });

    this.updateCount();
  }

  closeDrawer() {
    const drawer = document.querySelector('.drawer-open');

    if (drawer) {
      drawer.classList.remove('drawer-open');
      document.querySelector('body').classList.remove('has-drawer');
      document.querySelector('.drawer-overlay').style.display = 'none';
    }
  }
}
