import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  pillCountAction = [
    'selected_filter_option',
    'deselected_filter_option',
    'pill_deleted',
    'applied_filter_category_desktop',
    'applied_filters_mobile',
  ];

  getGoogleAnaylticsAttribute(element, name) {
    const attributeName = `data-ga-filters-${name}`;
    const elementWithData = element.closest(`[${attributeName}]`);
    return elementWithData
      ? elementWithData.getAttribute(attributeName)
      : element.getAttribute('data-ga-filters-parent-title') || null;
  }

  push(event) {
    const eventType = this.getGoogleAnaylticsAttribute(event.target, 'event');
    const eventAction = this.getGoogleAnaylticsAttribute(event.target, 'event-action');
    const filterName = this.getGoogleAnaylticsAttribute(event.target, 'filter-name') || 'N/A';
    const filterOption = this.getGoogleAnaylticsAttribute(event.target, 'filter-option') || 'N/A';
    const sortApplied = this.getGoogleAnaylticsAttribute(event.target, 'sort-applied') || 'N/A';

    let pillCount = 'N/A';

    if (this.pillCountAction.includes(eventAction)) {
      pillCount = document.querySelectorAll('[data-ga-filters] [data-ga-filters-event-action="pill_deleted"]').length;

      if (eventAction === 'pill_deleted') {
        pillCount -= 1;
      }
    }

    const data = {
      event: eventType,
      filter_action: eventAction,
      filter_category_name: filterName,
      filter_option: filterOption,
      pill_count: pillCount,
      sort_applied: eventAction === 'sort_applied' ? sortApplied : 'N/A',
    };

    dataLayer.push(data);
  }
}
