import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['reviewsSortForm'];

  searchReviewSort() {
    Rails.fire(this.reviewsSortFormTarget, 'submit');
  }

  handleReviewsSortResult(event) {
    const oldReviewsPages = this.element.querySelectorAll('[data-load-more-pagination-id-value="reviews"]');

    oldReviewsPages.forEach((element) => element.remove());

    const newSortedReviews = event.detail[0].querySelector('[data-load-more-pagination-id-value="reviews"]');
    this.element.appendChild(newSortedReviews);
  }
}
