import { Controller } from '@hotwired/stimulus';

const LITRES_PER_GALLON = 4.54609188;

export default class extends Controller {
  static targets = [
    'mileageInput',
    'mileage',
    'saving',
    'efficiency',
    'iceCostEstimate',
    'electricCostEstimate',
    'offPeakCostEstimate',
    'avgIceFuelPrice',
    'avgIceMpg',
  ];

  static values = {
    usableBattery: Number,
    realRange: Number,
    epgOffPeak: Number,
    homeOffPeak: Number,
    dieselPrice: Number,
    petrolPrice: Number,
    dieselMpg: Number,
    petrolMpg: Number,
  };

  connect() {
    this.calculate();
    this.updateSlider();
  }

  formatNumberWithCommas(number) {
    return Math.round(number).toLocaleString('en-GB');
  }

  calculate() {
    const avgIceFuelPrice = (this.dieselPriceValue + this.petrolPriceValue) / 2;
    const avgIceMpg = (this.dieselMpgValue + this.petrolMpgValue) / 2;
    const iceTotalLitres = (this.mileageInputTarget.value / avgIceMpg) * LITRES_PER_GALLON;
    const iceCostEstimate = (this.mileageInputTarget.value / avgIceMpg) * LITRES_PER_GALLON * (avgIceFuelPrice / 100);
    const efficiency = this.realRangeValue / this.usableBatteryValue;
    const requiredKWh = this.mileageInputTarget.value / efficiency;
    const electricCostEstimate = (requiredKWh * this.epgOffPeakValue) / 100;
    const electricHomeCostEstimate = (requiredKWh * this.homeOffPeakValue) / 100;
    const estimatedSavings = iceCostEstimate - electricCostEstimate;
    const epgCostPerMile = (electricCostEstimate / this.mileageInputTarget.value) * 100;
    const homeCostPerMile = (electricHomeCostEstimate / this.mileageInputTarget.value) * 100;

    const dieselTotalLitres = (this.mileageInputTarget.value / this.dieselMpgValue) * LITRES_PER_GALLON;
    const dieselAnnualCost = (this.dieselPriceValue * dieselTotalLitres) / 100;
    const dieselCostPerMile = (dieselAnnualCost / this.mileageInputTarget.value) * 100;

    const petrolTotalLitres = (this.mileageInputTarget.value / this.petrolMpgValue) * LITRES_PER_GALLON;
    const petrolAnnualCost = (this.petrolPriceValue * petrolTotalLitres) / 100;
    const petrolCostPerMile = (petrolAnnualCost / this.mileageInputTarget.value) * 100;

    this.mileageTarget.innerHTML = this.formatNumberWithCommas(this.mileageInputTarget.value);
    this.savingTarget.innerHTML = this.formatNumberWithCommas(estimatedSavings);
    this.iceCostEstimateTarget.innerHTML = this.formatNumberWithCommas(iceCostEstimate);
    this.electricCostEstimateTarget.innerHTML = this.formatNumberWithCommas(electricCostEstimate);
    this.offPeakCostEstimateTarget.innerHTML = this.formatNumberWithCommas(electricHomeCostEstimate);
    this.avgIceFuelPriceTarget.innerHTML = (avgIceFuelPrice / 100).toFixed(2);

    this.updateTargets('mileage', this.formatNumberWithCommas(this.mileageInputTarget.value));
    this.updateTargets('ice-total-litres', this.formatNumberWithCommas(iceTotalLitres));
    this.updateTargets('required-KWh', this.formatNumberWithCommas(requiredKWh));
    this.updateTargets('epg-off-peak', this.epgOffPeakValue);
    this.updateTargets('home-off-peak', this.homeOffPeakValue);
    this.updateTargets('ev-efficiency', efficiency);
    this.updateTargets('diesel-mpg', this.dieselMpgValue);
    this.updateTargets('petrol-mpg', this.petrolMpgValue);
    this.updateTargets('ice-average-price', (avgIceFuelPrice / 100).toFixed(2));
    this.updateTargets('diesel-price', (this.dieselPriceValue / 100).toFixed(2));
    this.updateTargets('petrol-price', (this.petrolPriceValue / 100).toFixed(2));
    this.updateTargets('epg-cost-per-mile', epgCostPerMile.toFixed(1));
    this.updateTargets('home-cost-per-mile', homeCostPerMile.toFixed(1));
    this.updateTargets('ice-average-mpg', avgIceMpg.toFixed(1));
    this.updateTargets('ice-annual-cost', this.formatNumberWithCommas(iceCostEstimate.toFixed(2)));
    this.updateTargets('epg-annual-cost', electricCostEstimate.toFixed(2));
    this.updateTargets('home-annual-cost', electricHomeCostEstimate.toFixed(2));
    this.updateTargets('diesel-cost-per-mile', dieselCostPerMile.toFixed(1));
    this.updateTargets('petrol-cost-per-mile', petrolCostPerMile.toFixed(1));
  }

  updateSlider() {
    const value =
      ((this.mileageInputTarget.value - this.mileageInputTarget.min) /
        (this.mileageInputTarget.max - this.mileageInputTarget.min)) *
      100;

    this.mileageInputTarget.style.background = `linear-gradient(to right, #00a3e0 0%, #00a3e0 ${value}%, #eaeaea ${value}%, #eaeaea 100%)`;
  }

  updateTargets(target, value) {
    document.querySelectorAll(`[data-target=${target}]`).forEach((element) => {
      element.innerHTML = value;
    });
  }
}
