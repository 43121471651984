import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  toggle(event) {
    event.preventDefault();
    const $triggers = $('[data-nav-collapse]');
    const trigger = event.currentTarget;
    const targetContainer = `#${trigger.href.split('#')[1]}`;
    const $triggerWrapper = $('.g-search__options')[0];

    if ($triggers && $triggers.length) {
      $triggerWrapper.classList.remove('is-active');

      for (let i = 0; i < $triggers.length; i++) {
        const targetContainerNested = `#${$triggers[i].href.split('#')[1]}`;

        $triggers[i].classList.remove('is-active');

        if (trigger === $triggers[i]) {
          $(`${targetContainerNested}`).collapse('hide');
        } else {
          $(`${targetContainerNested}`).removeClass('show');
        }
      }
    }

    $triggerWrapper.classList.add('is-active');

    document.querySelectorAll(`[data-nav-collapse][aria-controls="${targetContainer}"]`).forEach((item) => {
      item.classList.add('is-active');
    });

    $(targetContainer).collapse('show');
    $('.lazy', targetContainer).removeClass('lazy');
  }

  hide(event) {
    const $triggerWrapper = $('.g-search__options')[0];
    const $activeDropdown = $('.g-search__reveals .collapse.show')[0];

    if (
      event &&
      ($(event.target).closest('[data-nav-collapse]').length || $(event.target).closest('[data-nav-dropdown]').length)
    ) {
      return;
    }

    if ($activeDropdown) {
      $triggerWrapper.classList.remove('is-active');
      $(`#${$activeDropdown.id}`).collapse('hide');
    }
  }
}
