import { Controller } from '@hotwired/stimulus';

const slideToggle = (elem, hidden) => {
  elem.style.height = `${hidden ? elem.scrollHeight : 0}px`;
};

export default class extends Controller {
  static targets = ['button', 'container'];

  _handle($button) {
    $button.classList.toggle('open');

    const shown = $button.classList.contains('open');

    $button.setAttribute('aria-expanded', shown);

    const $target = this.element.querySelector($button.getAttribute('data-target'));

    slideToggle($target, shown);
  }

  toggle(event) {
    const $button = event.target;

    // take care of any other open sections
    if (!$button.classList.contains('open')) {
      this.buttonTargets.forEach(($el) => {
        if ($el.classList.contains('open')) {
          this._handle($el);
        }
      });
    }

    this._handle($button);
  }

  connect() {
    // Delay the slideToggle call by an arbitrary amount of time, e.g., 500ms
    // The reason is to calculate needed heigh after the fonts are loaded
    setTimeout(() => {
      slideToggle(this.containerTargets[0], true);
    }, 500); // Adjust the time as needed
  }
}
