import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['trigger', 'results'];

  nextPage() {
    const currentPage = parseInt(this.data.get('current-page'), 10);
    const totalRecords = parseInt(this.data.get('total-records'), 10);
    const resultsTargetSelector = '[data-load-more-target="results"]';
    const previousScrollY = window.scrollY;

    const nextPageUrl = `${window.location.pathname}.js?page=${currentPage + 1}`;

    fetch(nextPageUrl)
      .then((response) => response.text())
      .then((html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const newRecords = doc.querySelectorAll(`${resultsTargetSelector} > *`);

        // insert the new records
        document.querySelector(resultsTargetSelector).append(...newRecords);
        // update the page counter
        this.data.set('current-page', currentPage + 1);
        // reset scroll position
        window.scrollTo(window.scrollX, previousScrollY);

        // disable button if reached total records
        if (document.querySelectorAll(`${resultsTargetSelector} > *`).length === totalRecords) {
          this.triggerTarget.disabled = true;
        }
      });
  }
}
