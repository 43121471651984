import { Controller } from '@hotwired/stimulus';

const debug = false;

export default class extends Controller {
  getGoogleAnaylticsAttribute(element, name) {
    const attributeName = `data-ga-${name}`;
    const elementWithData = element.closest(`[${attributeName}]`);
    return elementWithData
      ? elementWithData.getAttribute(attributeName)
      : element.getAttribute('data-ga-parent-title') || null;
  }

  push(event) {
    const eventType = this.getGoogleAnaylticsAttribute(event.target, 'event');
    const eventName = this.getGoogleAnaylticsAttribute(event.target, 'event-name');
    const eventAction = this.getGoogleAnaylticsAttribute(event.target, 'event-action');
    const filterName = this.getGoogleAnaylticsAttribute(event.target, 'filter-name');
    const filterOption = this.getGoogleAnaylticsAttribute(event.target, 'filter-option');

    const data = {
      event: eventType,
      eventName,
      eventAction,
    };

    if (filterName) {
      data.filterName = filterName;
    }

    if (filterOption) {
      data.filterOption = filterOption;
    }

    if (eventType && eventName && eventAction) {
      dataLayer.push(data);
    } else if (debug) {
      console.warn('Google Analytics event: Unable to create event with missing data', data);
    }
  }
}
