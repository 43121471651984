import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  closeLightbox(event) {
    const mainElement = document.getElementsByTagName('main');

    if (event.target !== event.currentTarget || mainElement.length === 0) {
      return;
    }

    document.body.style.overflowY = 'auto';
    mainElement[0].style.zIndex = '1';
    this.element.style.display = 'none';
  }
}
