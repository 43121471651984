import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['trigger', 'results'];

  onChange(event) {
    const resultsTargetSelector = '[data-filter-reviews-target="results"]';
    const previousScrollY = window.scrollY;

    const { channel } = event.target.dataset;
    const make = event.target.value;

    fetch(`/${channel}/reviews${make ? `/${make}` : ''}`)
      .then((response) => response.text())
      .then((html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const newRecords = doc.querySelector(resultsTargetSelector).innerHTML;
        // insert the new records
        document.querySelector(resultsTargetSelector).innerHTML = newRecords;
        // reset scroll position
        window.scrollTo(window.scrollX, previousScrollY);
      });
  }
}
