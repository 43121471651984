import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const token = document.querySelector('[name=csrf-token]').content;

    const input = document.createElement('input');

    input.setAttribute('type', 'hidden');
    input.setAttribute('name', 'authenticity_token');
    input.setAttribute('value', token);

    // append to form element.
    this.element.appendChild(input);
  }
}
