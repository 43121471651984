import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['value'];

  toggle() {
    const isBusiness = this.valueTarget.checked;

    if (isBusiness) {
      document.cookie = 'pricing_type=is-business;path=/';
    } else {
      document.cookie = 'pricing_type=is-personal;path=/';
    }
    Turbolinks.visit(window.location);
  }
}
