import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['image', 'thumb'];

  show(event) {
    const $thumb = event.currentTarget;
    const thumb_index = this.thumbTargets.indexOf($thumb);

    for (let image_index = 0; image_index < this.imageTargets.length; image_index++) {
      if (image_index === thumb_index) {
        this.imageTargets[image_index].style.display = 'inline';
      } else {
        this.imageTargets[image_index].style.display = 'none';
      }
    }
  }
}
