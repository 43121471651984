import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['list', 'contents'];

  sluggify(string) {
    return string.toLowerCase().replace(/[^\w]/g, '-');
  }

  connect() {
    if (!this.listTarget) return;

    const title = this.element.getAttribute('data-contents-list-title');
    const headingElement = this.element.getAttribute('data-contents-list-heading-element');

    const listItems = [];

    Array.from(this.contentsTarget.querySelectorAll(headingElement)).forEach((heading) => {
      const text = heading.innerText;
      const slug = heading.name || this.sluggify(heading.innerText);

      heading.setAttribute('name', slug);

      listItems.push(`
        <li>
          <a data-section-scroll href="#${slug}">${text}</a>
        </li>
      `);
    });

    this.listTarget.innerHTML = `
      <h2>${title}</h2>

      <ol class="pl-3" style="list-style: disc">
        ${listItems.join('\n')}
      </ol>
    `;
  }
}
