import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.updateSidebarPosition = this.updateSidebarPosition.bind(this);
    window.addEventListener('scroll', this.updateSidebarPosition);
    this.isSticky = false; // Track sticky state
    this.isStopped = false; // Track stop state
    this.stickyScrollPosition = null; // Track sidebar sticky scroll position
    this.stoppedScrollPosition = null; // Track sidebar stop scroll position
  }

  disconnect() {
    window.removeEventListener('scroll', this.updateSidebarPosition);
  }

  updateSidebarPosition() {
    const sidebar = this.element;
    const sidebarWrapper = sidebar.parentElement;
    const sidebarRect = sidebar.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const stopElement = document.getElementById('recommended-deals');
    const stopScrollLocation = stopElement.getBoundingClientRect().top + window.scrollY - 125;
    const sidebarBottomPosition = window.scrollY + sidebarRect.bottom;

    if (this.shouldStick(sidebarRect, viewportHeight)) {
      this.makeSticky(sidebar);
    } else if (this.shouldUnstick()) {
      this.removeSticky(sidebar);
    }

    if (this.shouldStop(sidebarBottomPosition, stopScrollLocation)) {
      this.stopSidebar(sidebar, sidebarWrapper, stopScrollLocation, sidebarRect.height);
    }

    if (this.shouldResume()) {
      this.resumeSticky(sidebar, sidebarWrapper, stopScrollLocation, sidebarRect.height);
    }
  }

  shouldStick(sidebarRect, viewportHeight) {
    return !this.isSticky && sidebarRect.bottom <= viewportHeight && !this.isStopped;
  }

  shouldUnstick() {
    return this.isSticky && window.scrollY <= this.stickyScrollPosition;
  }

  shouldStop(sidebarBottomPosition, stopScrollLocation) {
    return !this.isStopped && sidebarBottomPosition >= stopScrollLocation;
  }

  shouldResume() {
    return this.isStopped && window.scrollY < this.stoppedScrollPosition;
  }

  makeSticky(sidebar) {
    sidebar.classList.add('sticky');
    this.isSticky = true;
    this.stickyScrollPosition = window.scrollY; // Save the scroll position when it becomes sticky
  }

  removeSticky(sidebar) {
    sidebar.classList.remove('sticky');
    this.isSticky = false;
  }

  stopSidebar(sidebar, sidebarWrapper, stopScrollLocation, sidebarHeight) {
    this.isStopped = true;
    this.stoppedScrollPosition = window.scrollY; // Save the scroll position when the sidebar stops
    sidebar.classList.remove('sticky');
    sidebarWrapper.style.position = 'absolute';
    sidebarWrapper.style.top = `${stopScrollLocation - sidebarHeight}px`;
  }

  resumeSticky(sidebar, sidebarWrapper, stopScrollLocation, sidebarHeight) {
    const scrollBackDistance = this.stoppedScrollPosition - window.scrollY;
    sidebarWrapper.style.top = `${Math.max(stopScrollLocation - sidebarHeight - scrollBackDistance, 0)}px`;

    if (window.scrollY < stopScrollLocation) {
      sidebar.classList.add('sticky');
      sidebarWrapper.style.position = 'absolute';
      sidebarWrapper.style.top = '';
      this.isStopped = false;
    }
  }
}
